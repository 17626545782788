import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import BoundaryErrorFallback from './fallback';
import authClient from './lib/auth-client';
import RouteTable from './router';
import BrowserRouter from './router/BrowserHistory';
import { history } from './router/history';
import useAuthStore from './store/auth/auth.store';
import { theme } from './styles/theme';
import { Role } from './types/components';

const Router = () => {
  const { setUserInfo } = useAuthStore();
  useEffect(() => {
    authClient.onAuthStateChange((_event: any, session: any) => {
      console.log(_event);
      setUserInfo({
        id: session?.id,
        name: session?.name,
        role: session?.role as Role,
        shop: session?.shop
      });
    });
  }, []);
  return (
    <BrowserRouter history={history}>
      <MantineProvider theme={theme} defaultColorScheme="auto">
        <ErrorBoundary FallbackComponent={BoundaryErrorFallback}>
          <ModalsProvider>
            <Notifications position="top-center" limit={5} zIndex={999}></Notifications>
            <RouteTable />
          </ModalsProvider>
        </ErrorBoundary>
      </MantineProvider>
    </BrowserRouter>
  );
};

const App = () => {
  return (
    <div className="App">
      <Router />
    </div>
  );
};

export default App;
