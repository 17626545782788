import { NavbarLink } from './interface';

import { IconAdjustmentsAlt, IconBox, IconReportMoney, IconUsers } from '@tabler/icons-react';

export const shopNavbarLinks: NavbarLink[] = [
  {
    link: '/',
    label: 'Đơn hàng',
    icon: IconReportMoney
  },
  {
    link: '/customers',
    label: 'Khách hàng',
    icon: IconUsers
  },
  {
    link: '/products',
    label: 'Sản phẩm',
    icon: IconBox,
    childLinks: [
      {
        link: '/products',
        label: 'Danh sách chung'
      },
      {
        link: '/products-private',
        label: 'Danh sách riêng'
      }
    ]
  },
  // {
  //   link: '/discounts',
  //   label: 'Khuyến mãi',
  //   icon: IconDiscount
  // },
  // {
  //   link: '/faqs',
  //   label: 'Nội dung',
  //   icon: IconArticle,
  //   childLinks: [
  //     {
  //       link: '/faqs',
  //       label: 'Câu hỏi thường gặp'
  //     },
  //     {
  //       link: '/reviews',
  //       label: 'Đánh giá'
  //     },
  //     {
  //       link: '/notices',
  //       label: 'Thông báo'
  //     }
  //   ]
  // },
  {
    link: '/settings',
    label: 'Cài đặt',
    icon: IconAdjustmentsAlt,
    childLinks: [
      {
        link: '/settings',
        label: 'Cài đặt chung'
      },
      {
        link: '/settings/staff',
        label: 'Nhân viên'
      }
    ],
    disableForEditor: true
  }
];
