import { useEffect } from 'react';
import type { Location } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import { getMe } from '../api/get-me';

import { RoleEnum } from '@/types/enums';
import { token } from '../lib/token';
import { notFoundRoute, whiteList } from '../router/routes';
import { generateRoutes } from '../router/utils';
import { useRouterStore } from '../store';
import useAuthStore from '../store/auth/auth.store';
import { Role } from '../types/components';

const useRouterGuard = (to: Location) => {
  const { pathname } = useLocation();
  const { userInfo, setUserInfo, setRetrying } = useAuthStore();
  const navigate = useNavigate();
  const { addRoutes } = useRouterStore();

  useEffect(() => {
    let isCancelled = false;
    const permission = async () => {
      if (whiteList.includes(pathname)) return;

      if (!token.get() || userInfo?.role.includes(RoleEnum.USER)) {
        setUserInfo(null);
        return navigate('/login', { replace: true, state: { to } });
      }

      if (userInfo?.role) {
        return;
      } else {
        try {
          setRetrying(true);
          const response = await getMe();

          setRetrying(false);
          if (isCancelled) return;

          if (response?.data) {
            const routes = generateRoutes(response?.data.role as Role);

            setUserInfo({
              id: response?.data.id,
              name: response?.data.name,
              role: response?.data.role as Role,
              avatar: response?.data.avatar,
              shop: response.data.shop
            });

            addRoutes([...routes, notFoundRoute]);
          } else {
            setUserInfo(null);
            navigate('/login');
          }
        } catch {
          setUserInfo(null);
          setRetrying(false);
          navigate('/login');
        }
      }
    };

    permission();

    return () => {
      isCancelled = true;
    };
  }, [pathname]);
};

export default useRouterGuard;
