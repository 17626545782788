export enum RoleEnum {
  ADMIN_SYS = 'admin_super',
  ADMIN_EDITOR = 'admin_editor',
  SHOP_OWNER = 'shop_owner',
  SHOP_EDITOR = 'shop_editor',
  USER = 'user'
}

export enum SettingGroupEnum {
  SYSTEM = 'system'
}

export const ActivityLogActionTranslation: Record<string, string> = {
  create: 'created',
  update: 'updated',
  delete: 'deleted',
  modify: 'modified',
  upload: 'uploaded'
} as const;

export enum OrderStatusEnum {
  OPEN = 'open', // accepted
  COMPLETED = 'completed',
  CANCELED = 'canceled'
}

export enum AppNameEnum {
  DASHBOARD_IMPERSONATING = 'dashboard_impersonating',
  ONLINE = 'online',
  DASHBOARD = 'dashboard'
}
