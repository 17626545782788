import config from '@/config';
import { useIsomorphicEffect } from '@mantine/hooks';
import { useRef } from 'react';

export function useJsScript(jsScript: string) {
  const script = useRef<HTMLScriptElement>();

  useIsomorphicEffect(() => {
    const isDev = config.app.environment === 'development';
    if (!jsScript || isDev) {
      return;
    }

    if (!script.current) {
      const element = document.createElement('script');
      element.type = 'text/javascript';
      element.text = jsScript;
      script.current = element;
      document.querySelector('head')!.appendChild(element);
    }
  }, [jsScript]);
}
