import { Button, Flex, Group, Stack, Text, Title } from '@mantine/core';
import classes from './fallback.module.css';

const BoundaryErrorFallback = () => {
  return (
    <Flex mih={50} gap="md" justify="center" align="center" direction="row" wrap="wrap">
      <Stack h={300} className={classes.content}>
        <Title className={classes.title}>Có lỗi gì đó...</Title>
        <Text c="dimmed" size="lg" className={classes.description}>
          Bạn thử làm mới trang xem sao, nhấn nút dưới đây. Cảm ơn bạn.
        </Text>
        <Group justify="center">
          <Button size="md" onClick={() => location.reload()}>
            Làm mới trang
          </Button>
        </Group>
      </Stack>
    </Flex>
  );
};

export default BoundaryErrorFallback;
