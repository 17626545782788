import { RoleTranslation } from '@/lib/constants';
import useAuthStore from '@/store/auth/auth.store';
import { Group, Image, Text, UnstyledButton, rem } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import classes from './Footer.module.css';

export function Footer() {
  const { userInfo } = useAuthStore();

  return (
    <UnstyledButton component={Link} to="/settings" className={classes.user}>
      <Group>
        <Image src={userInfo?.shop?.logo} w={40} fallbackSrc="https://placehold.co/50x50?text=C" />

        <div style={{ flex: 1 }}>
          <Text size="sm" fw={500}>
            {userInfo?.shop?.name}
          </Text>
          {userInfo?.role && (
            <Text c="dimmed" size="xs">
              {RoleTranslation[userInfo.role]}
            </Text>
          )}
        </div>

        <IconChevronRight style={{ width: rem(14), height: rem(14) }} stroke={1.5} />
      </Group>
    </UnstyledButton>
  );
}
