import { createTheme } from '@mantine/core';

export const theme = createTheme({
  scale: 1.6,
  colors: {
    'more-dark': [
      '#F8FAFC',
      '#D8E5F7',
      '#B4CBEF',
      '#829FD0',
      '#5670A2',
      '#263964',
      '#1B2B56',
      '#132048',
      '#0C153A',
      '#070E30'
    ],
    'little-light': [
      '#F3F6FE',
      '#EFF3FC',
      '#DFE8F9',
      '#C9D3EC',
      '#B1BCDB',
      '#929EC3',
      '#6A78A7',
      '#49568C', //
      '#2E3971',
      '#1C255D'
    ],
    'baseweb-black': [
      '#FFFFFF',
      '#F6F6F6',
      '#EEEEEE',
      '#E2E2E2',
      '#CBCBCB',
      '#AFAFAF',
      '#757575',
      '#545454',
      '#333333',
      '#000000'
    ],
    'ocean-blue': [
      '#7AD1DD',
      '#5FCCDB',
      '#44CADC',
      '#2AC9DE',
      '#1AC2D9',
      '#11B7CD',
      '#09ADC3',
      '#0E99AC',
      '#128797',
      '#147885'
    ]
  },
  primaryColor: 'ocean-blue',
  defaultRadius: 'sm'
  // loader: 'dots',
});
