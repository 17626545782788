import { AppNameEnum } from '@/types/enums';
import config from '../config';
import { AnyRecord } from '../utils/types';
import { httpClient } from './http';
import { impersonateToken, token } from './token';
import { uuid } from './uuid';

const DEFAULT_OPTIONS = {
  autoRefreshToken: true,
  headers: {},
  persistSession: true,
  url: config.api.host
};

interface IAuthOptions {
  autoRefreshToken: boolean;
  headers: AnyRecord;
  persistSession: boolean;
  url: string;
}

interface IAuthUser {
  id: string;
  role: string;
  name: string;
}

interface IAuthUserSession {
  id: string;
  role: string;
  name: string;
  session: {
    access_token: string;
    refresh_token: string;
    expired_at: number;
  };
}

interface ICallbackSubscription {
  id: string;
  callback: (event: string, data: any) => any;
  unsubscribe: () => any;
}

class AuthClient {
  private options: IAuthOptions;

  private refreshTokenTimer: number | undefined;

  private currentUser: IAuthUser | undefined;

  private currentSession: IAuthUserSession | undefined;

  private stateChangeEmitters = new Map<string, ICallbackSubscription>();

  constructor(options?: IAuthOptions) {
    this.options = {
      ...DEFAULT_OPTIONS,
      ...(options && options)
    };

    this.recoverSession();
    this.recoverAndRefresh();
  }

  getUser(): IAuthUser | undefined {
    return this.currentUser;
  }

  getSession(): IAuthUserSession | undefined {
    return this.currentSession;
  }

  private recoverSession() {
    try {
      const data = token.get();
      const { currentSession, expiresAt } = data || {};
      const timeNow = Date.now();

      if (expiresAt >= timeNow && currentSession?.session) {
        console.log('Recovering session');
        this.saveSession(currentSession);
        this.notifyAllSubscribers('SIGNED_IN');
      }
    } catch (error) {
      console.log('error', error);
    }
  }

  private async recoverAndRefresh() {
    try {
      const data = token.get();
      const { currentSession, expiresAt } = data || {};
      const timeNow = Date.now();

      if (expiresAt < timeNow) {
        if (this.options.autoRefreshToken && currentSession?.session?.refresh_token) {
          console.log('Recovering session and refresh');
          try {
            this.currentSession = currentSession;
            await this.callRefreshToken();
          } catch (error: any) {
            console.log(error?.message);
            this.removeSession();
          }
        } else {
          this.removeSession();
        }
      } else if (!currentSession || !currentSession.session) {
        console.log('Current session is missing data.');
        this.removeSession();
      } else {
        // Should be handled in recoverSession method already
        // But we still need the code here to accommodate for AsyncStorage e.g. in React native
        this.saveSession(currentSession);
        this.notifyAllSubscribers('SIGNED_IN');
      }
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  async requestCode(username: string): Promise<void> {
    if (!username) throw new Error('You must provide username');
    await httpClient.post<{ data: IAuthUserSession }>('/v1/auth/login_request', {
      username
    });
  }

  async signOut(): Promise<void> {
    this.removeSession();
    Promise.resolve();
  }

  async signIn(username: string, code: string): Promise<void> {
    this.removeSession();

    if (!username || !code) throw new Error('You must provide username and login code');

    const result = await httpClient.post<{ data: IAuthUserSession }>('/v1/auth/login', {
      username,
      otp: code,
      app_name: AppNameEnum.DASHBOARD
    });

    if (result) {
      this.saveSession(result.data);
      this.notifyAllSubscribers('SIGNED_IN');
    }
  }

  onAuthStateChange(callback: any): void {
    const id = uuid();
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const subscription = {
      callback,
      id,
      unsubscribe: () => {
        self.stateChangeEmitters.delete(id);
      }
    };
    this.stateChangeEmitters.set(id, subscription);
  }

  async callRefreshToken(): Promise<void> {
    if (!this.currentSession?.session?.refresh_token) {
      throw new Error('No current session.');
    }
    const result = await httpClient.post<{ data: IAuthUserSession }>('/v1/auth/refresh_token', {
      token: this.currentSession?.session?.refresh_token,
      app_name: AppNameEnum.DASHBOARD
    });
    if (result) {
      this.saveSession(result.data);
      this.notifyAllSubscribers('REFRESHED_TOKEN');
    }
  }

  endImpersonate() {
    const impersonatedSession = impersonateToken.get();
    this.saveSession(impersonatedSession);
    impersonateToken.set(null);
  }

  impersonate(session: IAuthUserSession) {
    impersonateToken.set(JSON.stringify(this.currentSession));

    this.saveSession(session);
  }

  private notifyAllSubscribers(event: string): void {
    for (const [, x] of this.stateChangeEmitters) x.callback(event, this.currentSession);
  }

  private saveSession(session: IAuthUserSession): void {
    this.currentSession = session;
    this.currentUser = {
      id: session.id,
      name: session.name,
      role: session.role
    };

    const expiresAt = session.session?.expired_at;
    if (expiresAt) {
      const refreshDurationBeforeExpires = Number(expiresAt) - Date.now() - 5000;

      this.startAutoRefreshToken(refreshDurationBeforeExpires);
    }

    this.persistSession(this.currentSession);
  }

  private persistSession(currentSession: IAuthUserSession): void {
    const data = { currentSession, expiresAt: currentSession.session?.expired_at };
    token.set(JSON.stringify(data));
  }

  private removeSession(): void {
    console.log('Removing current session.');
    this.currentSession = undefined;
    this.currentUser = undefined;

    if (this.refreshTokenTimer) clearTimeout(this.refreshTokenTimer);

    token.set(null);
  }

  private startAutoRefreshToken(value: number): void {
    if (this.refreshTokenTimer) {
      clearTimeout(this.refreshTokenTimer);
    }
    if (value <= 0 || !this.options.autoRefreshToken) return;

    this.refreshTokenTimer = setTimeout(() => this.callRefreshToken(), value) as unknown as number;
  }
}

export default new AuthClient();
