import Loadable from '@/components/loadable';
import { RoleEnum } from '@/types/enums';
import { Route } from '@/types/router';

export const shopAsyncRoutes: Route[] = [
  {
    path: '',
    name: 'index',
    component: Loadable({
      loader: () => import('@/modules/shop/order/List')
    }),
    meta: {
      roles: [RoleEnum.SHOP_EDITOR, RoleEnum.SHOP_OWNER]
    }
  },
  {
    path: 'discounts',
    name: 'discount',
    component: Loadable({
      loader: () => import('@/modules/shop/discount/List')
    }),
    meta: {
      roles: [RoleEnum.SHOP_EDITOR, RoleEnum.SHOP_OWNER]
    }
  },
  {
    path: 'customers',
    name: 'customer',
    component: Loadable({
      loader: () => import('@/modules/shop/customer/List')
    }),
    meta: {
      roles: [RoleEnum.SHOP_EDITOR, RoleEnum.SHOP_OWNER]
    }
  },
  {
    path: 'orders',
    name: 'order',
    component: Loadable({
      loader: () => import('@/modules/shop/order/List')
    }),
    meta: {
      roles: [RoleEnum.SHOP_EDITOR, RoleEnum.SHOP_OWNER]
    }
  },
  {
    path: 'products',
    name: 'product',
    component: Loadable({
      loader: () => import('@/modules/shop/product/List')
    }),
    meta: {
      roles: [RoleEnum.SHOP_EDITOR, RoleEnum.SHOP_OWNER]
    }
  },
  {
    path: 'products-private',
    name: 'productToApprove',
    component: Loadable({
      loader: () => import('@/modules/shop/product/private/List')
    }),
    meta: {
      roles: [RoleEnum.SHOP_EDITOR, RoleEnum.SHOP_OWNER]
    }
  },
  {
    path: 'products-private/create',
    name: 'productPrivateCreate',
    component: Loadable({
      loader: () => import('@/modules/shop/product/private/Create')
    }),
    meta: {
      roles: [RoleEnum.SHOP_EDITOR, RoleEnum.SHOP_OWNER]
    }
  },
  {
    path: 'products-private/:id/update',
    name: 'productPrivateCreateUpdate',
    component: Loadable({
      loader: () => import('@/modules/shop/product/private/Update')
    }),
    meta: {
      roles: [RoleEnum.ADMIN_EDITOR, RoleEnum.ADMIN_SYS]
    }
  },
  {
    path: 'products/:id/update',
    name: 'productUpdate',
    component: Loadable({
      loader: () => import('@/modules/shop/product/update')
    }),
    meta: {
      roles: [RoleEnum.ADMIN_EDITOR, RoleEnum.ADMIN_SYS]
    }
  },
  {
    path: 'settings',
    name: 'setting',
    component: Loadable({
      loader: () => import('@/modules/shop/setting/Update')
    }),
    meta: {
      roles: [RoleEnum.SHOP_EDITOR, RoleEnum.SHOP_OWNER]
    }
  },
  {
    path: 'settings/staff',
    name: 'settingStaff',
    component: Loadable({
      loader: () => import('@/modules/shop/setting/tabs/Staff')
    }),
    meta: {
      roles: [RoleEnum.SHOP_EDITOR, RoleEnum.SHOP_OWNER]
    }
  },
  {
    path: 'me',
    name: 'me',
    component: Loadable({
      loader: () => import('@/modules/shop/me/Update')
    }),
    meta: {
      roles: [RoleEnum.SHOP_EDITOR, RoleEnum.SHOP_OWNER]
    }
  },
  {
    path: 'me/pin',
    name: 'mePin',
    component: Loadable({
      loader: () => import('@/modules/shop/me/tabs/Pin')
    }),
    meta: {
      roles: [RoleEnum.SHOP_EDITOR, RoleEnum.SHOP_OWNER]
    }
  }
];
