import { EntityPropertyType, SelectOption } from '../utils/types';
import { IApiPaginationResponse } from './api';

export const PAGE_SIZE_SELECTIONS: SelectOption[] = [
  {
    value: '5',
    label: '5'
  },
  {
    value: '10',
    label: '10'
  },
  {
    value: '25',
    label: '25'
  },
  {
    value: '50',
    label: '50'
  }
];

export const COMMON_PROPERTIES = {
  id: {
    label: 'Id',
    type: EntityPropertyType.NUMBER
  }
};

export const DEFAULT_LIST_RESPONSE: IApiPaginationResponse = {
  records: [],
  page: 1,
  limit: 10,
  total: 0,
  pages: 0
};

export const ActivityLogActionTranslation: Record<string, string> = {
  create: 'created',
  update: 'updated',
  delete: 'deleted',
  modify: 'modified',
  upload: 'uploaded'
} as const;

export const TrueFalseTranslation: Record<string, string> = {
  true: 'Có',
  false: 'Không'
} as const;

export const DEFAULT_PAGE_SIZE = 25;
export const DEFAULT_PAGE_INDEX = 1;

export const RoleTranslation: Record<string, string> = {
  shop_owner: 'Chủ cửa hàng',
  shop_editor: 'Nhân viên',
  admin_super: 'Siêu quản trị',
  admin_editor: 'Biên tập viên'
} as const;
