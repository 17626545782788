import { produce } from 'immer';
import { adminAsyncRoutes, shopAsyncRoutes } from './routes';

import { RoleEnum } from '@/types/enums';
import type { Role } from '../types/components';
import type { Route } from '../types/router';

const filterRoutesByRole = (routes: Route[], role: Role) => {
  return routes.reduce(
    (res, route) => {
      if (route.meta?.roles.includes(role)) {
        const newRoute = produce(route, (draft) => {
          const { children } = draft;
          if (children) draft.children = filterRoutesByRole(children, role);
        });
        res.push(newRoute);
      }
      return res;
    },
    <Route[]>[]
  );
};

const generateRoutes = (role: Role) => {
  if (role.includes('admin')) {
    return role === RoleEnum.ADMIN_SYS ? adminAsyncRoutes : filterRoutesByRole(adminAsyncRoutes, role);
  }

  return role === RoleEnum.SHOP_OWNER ? shopAsyncRoutes : filterRoutesByRole(shopAsyncRoutes, role);
};

export { generateRoutes };
