import Loadable from '@/components/loadable';
import { RoleEnum } from '@/types/enums';
import { Route } from '@/types/router';

export const adminAsyncRoutes: Route[] = [
  {
    path: '',
    name: 'index',
    component: Loadable({
      loader: () => import('@/modules/admin/dashboard')
    }),
    meta: {
      roles: [RoleEnum.ADMIN_EDITOR, RoleEnum.ADMIN_SYS]
    }
  },
  {
    path: 'dashboard',
    name: 'dashboard',
    component: Loadable({
      loader: () => import('@/modules/admin/dashboard')
    }),
    meta: {
      roles: [RoleEnum.ADMIN_EDITOR, RoleEnum.ADMIN_SYS]
    }
  },
  {
    path: 'content',
    name: 'content',
    component: Loadable({
      loader: () => import('@/modules/admin/content')
    }),
    meta: {
      roles: [RoleEnum.ADMIN_EDITOR, RoleEnum.ADMIN_SYS]
    }
  },
  {
    path: 'discounts',
    name: 'discount',
    component: Loadable({
      loader: () => import('@/modules/admin/discount')
    }),
    meta: {
      roles: [RoleEnum.ADMIN_EDITOR, RoleEnum.ADMIN_SYS]
    }
  },
  {
    path: 'orders',
    name: 'order',
    component: Loadable({
      loader: () => import('@/modules/admin/order')
    }),
    meta: {
      roles: [RoleEnum.ADMIN_EDITOR, RoleEnum.ADMIN_SYS]
    }
  },
  {
    path: 'products',
    name: 'product',
    component: Loadable({
      loader: () => import('@/modules/admin/product/List')
    }),
    meta: {
      roles: [RoleEnum.ADMIN_EDITOR, RoleEnum.ADMIN_SYS]
    }
  },
  {
    path: 'products/create',
    name: 'productCreate',
    component: Loadable({
      loader: () => import('@/modules/admin/product/Create')
    }),
    meta: {
      roles: [RoleEnum.ADMIN_EDITOR, RoleEnum.ADMIN_SYS]
    }
  },
  {
    path: 'products/:id/update',
    name: 'productUpdate',
    component: Loadable({
      loader: () => import('@/modules/admin/product/Update')
    }),
    meta: {
      roles: [RoleEnum.ADMIN_EDITOR, RoleEnum.ADMIN_SYS]
    }
  },
  {
    path: 'shops',
    name: 'shop',
    component: Loadable({
      loader: () => import('@/modules/admin/shop/List')
    }),
    meta: {
      roles: [RoleEnum.ADMIN_EDITOR, RoleEnum.ADMIN_SYS]
    }
  },
  {
    path: 'shops/:id/update',
    name: 'shopUpdate',
    component: Loadable({
      loader: () => import('@/modules/admin/shop/update/Update')
    }),
    meta: {
      roles: [RoleEnum.ADMIN_EDITOR, RoleEnum.ADMIN_SYS]
    }
  },
  {
    path: 'shops/:id/staff',
    name: 'shopStaffUpdate',
    component: Loadable({
      loader: () => import('@/modules/admin/shop/update/tabs/Staff')
    }),
    meta: {
      roles: [RoleEnum.ADMIN_EDITOR, RoleEnum.ADMIN_SYS]
    }
  },
  {
    path: 'shops/:id/products',
    name: 'shopProductUpdate',
    component: Loadable({
      loader: () => import('@/modules/admin/shop/update/tabs/Product')
    }),
    meta: {
      roles: [RoleEnum.ADMIN_EDITOR, RoleEnum.ADMIN_SYS]
    }
  },
  {
    path: 'users',
    name: 'user',
    component: Loadable({
      loader: () => import('@/modules/admin/user/List')
    }),
    meta: {
      roles: [RoleEnum.ADMIN_EDITOR, RoleEnum.ADMIN_SYS]
    }
  },
  {
    path: 'users/create',
    name: 'userCreate',
    component: Loadable({
      loader: () => import('@/modules/admin/user/Create')
    }),
    meta: {
      roles: [RoleEnum.ADMIN_SYS]
    }
  },
  {
    path: 'users/:id/update',
    name: 'userUpdate',
    component: Loadable({
      loader: () => import('@/modules/admin/user/Update')
    }),
    meta: {
      roles: [RoleEnum.ADMIN_SYS]
    }
  },
  {
    path: 'categories',
    name: 'category',
    component: Loadable({
      loader: () => import('@/modules/admin/category')
    }),
    meta: {
      roles: [RoleEnum.ADMIN_EDITOR, RoleEnum.ADMIN_SYS]
    }
  },
  {
    path: 'elements',
    name: 'element',
    component: Loadable({
      loader: () => import('@/modules/admin/element')
    }),
    meta: {
      roles: [RoleEnum.ADMIN_EDITOR, RoleEnum.ADMIN_SYS]
    }
  },
  {
    path: 'faqs',
    name: 'faq',
    component: Loadable({
      loader: () => import('@/modules/admin/faq')
    }),
    meta: {
      roles: [RoleEnum.ADMIN_EDITOR, RoleEnum.ADMIN_SYS]
    }
  },
  {
    path: 'notices',
    name: 'notice',
    component: Loadable({
      loader: () => import('@/modules/admin/notice')
    }),
    meta: {
      roles: [RoleEnum.ADMIN_EDITOR, RoleEnum.ADMIN_SYS]
    }
  },
  {
    path: 'settings',
    name: 'setting',
    component: Loadable({
      loader: () => import('@/modules/admin/setting')
    }),
    meta: {
      roles: [RoleEnum.ADMIN_EDITOR, RoleEnum.ADMIN_SYS]
    }
  },
  {
    path: 'settings/payments',
    name: 'settingPayments',
    component: Loadable({
      loader: () => import('@/modules/admin/setting/tabs/Payment')
    }),
    meta: {
      roles: [RoleEnum.ADMIN_EDITOR, RoleEnum.ADMIN_SYS]
    }
  },
  {
    path: 'settings/seo',
    name: 'settingSeo',
    component: Loadable({
      loader: () => import('@/modules/admin/setting/tabs/Seo')
    }),
    meta: {
      roles: [RoleEnum.ADMIN_EDITOR, RoleEnum.ADMIN_SYS]
    }
  },
  {
    path: 'settings/popup',
    name: 'settingPopup',
    component: Loadable({
      loader: () => import('@/modules/admin/setting/tabs/Popup')
    }),
    meta: {
      roles: [RoleEnum.ADMIN_EDITOR, RoleEnum.ADMIN_SYS]
    }
  }
];
