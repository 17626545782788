import '@mantine/carousel/styles.css';
import '@mantine/core/styles.css';
import { notifications } from '@mantine/notifications';
import '@mantine/notifications/styles.css';
import '@mantine/tiptap/styles.css';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import 'mantine-react-table/styles.css';
import ReactDOM from 'react-dom/client';
import App from './app';
import { useJsScript } from './hooks/useScript';
import './styles/global.css';

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => {
      notifications.show({
        color: 'red',
        title: '😞',
        message: error?.message || 'Có lỗi xảy ra'
      });
    }
  })
});

const Root = () => {
  useJsScript(`
  (function(c,l,a,r,i,t,y){
      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
  })(window, document, "clarity", "script", "m0728q819e");
  `);
  return (
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  );
};

const rootElement = document.querySelector('#root');

if (rootElement) {
  ReactDOM.createRoot(rootElement).render(<Root />);
}
