import { NavbarLink } from './interface';

import {
  IconAdjustmentsAlt,
  IconArticle,
  IconBox,
  IconBuildingStore,
  IconDashboard,
  IconDiscount,
  IconReportMoney,
  IconUsers
} from '@tabler/icons-react';

export const adminNavbarLinks: NavbarLink[] = [
  {
    link: '/',
    label: 'Bảng điều khiển',
    icon: IconDashboard
  },
  {
    link: '/orders',
    label: 'Đơn hàng',
    icon: IconReportMoney
  },
  {
    link: '/shops',
    label: 'Cửa hàng',
    icon: IconBuildingStore
  },
  {
    link: '/products',
    label: 'Sản phẩm',
    icon: IconBox,
    childLinks: [
      {
        link: '/products',
        label: 'Sản phẩm'
      },
      {
        link: '/categories',
        label: 'Danh mục'
      },
      {
        link: '/elements',
        label: 'Hoạt chất'
      }
    ],
    disableForEditor: true
  },
  {
    link: '/users',
    label: 'Người dùng',
    icon: IconUsers
  },
  {
    link: '/content',
    label: 'Nội dung',
    icon: IconArticle,
    childLinks: [
      {
        link: '/content',
        label: 'Bài viết'
      },
      {
        link: '/faqs',
        label: 'Câu hỏi thường gặp'
      },
      {
        link: '/notices',
        label: 'Thông báo'
      }
    ]
  },
  {
    link: '/discounts',
    label: 'Khuyến mãi',
    icon: IconDiscount
  },
  {
    link: '/settings',
    label: 'Cài đặt',
    icon: IconAdjustmentsAlt,
    childLinks: [
      {
        link: '/settings',
        label: 'Cài đặt chung'
      },
      {
        link: '/settings/payments',
        label: 'Phương thức thanh toán'
      },
      {
        link: '/settings/seo',
        label: 'SEO'
      },
      {
        link: '/settings/popup',
        label: 'Popup tự động'
      }
    ],
    disableForEditor: true
  }
];
